import { CheckIcon } from '@heroicons/react/solid'
import React from 'react'
import CabecalhoImagem from '../../components/CabecalhoImagem'

export default function SolucoesAutomacao() {
    return (
        <main className='bg-gray-50 h-fit'>
            <CabecalhoImagem imagem="cabecalho/capa-automacao" titulo="Soluções em Automação" />


            <div className='flex flex-col divide-y-2 divide-red-600 max-w-7xl mx-auto lg:px-32'>
                <div>
                    <h1 className='w-full text-[#e39c00] text-3xl font-bold mt-16 z-10'>Sistema de automação para Varejo</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-8 pb-32 text-left md:p-16 p-8'>
                        <img src={require(`../../assets/negocios/Varejo.jpg`)} alt={"capa-automacao"} className="object-top object-cover w-full h-auto" />
                        <div className='flex flex-col space-y-2 lg:order-none order-last'>
                            <p className='mb-4 text-gray-500'>
                                Nosso sistema garante um gerenciamento de forma simples e prático com controle de estoque e financeiro, emissão de nota fiscal eletrônica NF-e, relatórios e geração de SPED Fiscal, entre outras funções.
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Emissão de Nota Fiscal Eletrônica (NF-e) com rapidez e segurança;</span>
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Controle financeiro e de estoque;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Banco de dados em nuvem;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Relatórios e geração de SPED Fiscal;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Consulta de notas emitidas direto do sistema</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Agilidade na abertura e fechamento de caixa;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Consulta rápida de produtos cadastrados.</span>
                            </p>
                        </div>
                    </div>
                    <button className='bg-[#e39c00] hover:bg-[#e05e14] text-white font-bold py-2 px-4 mt-4 mb-20 rounded-md transition duration-200 shadow hover:shador-2xl'>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='text-white font-bold'>
                            Fale agora com nosso setor de Automação
                        </a>
                    </button>
                </div>




                <div>
                    <h1 className='w-full text-[#e39c00] text-3xl font-bold mt-16 z-10'>Sistema de automação para Farmácia</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-8 pb-32 text-left md:p-16 p-8'>
                        <div className='flex flex-col space-y-2 lg:order-none order-last'>
                            <p className='mb-4 text-gray-500'>
                                Projetado por especialistas em tecnologia e gestão, o nosso sistema de farmácia desenvolve, automatiza e integra o ambiente farmacêutico em um único sistema.
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Banco de dados em nuvem;</span>
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Gerenciador financeiro completo com mensuração e indicadores de lucratividade diários;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Controle de estoque com planejador de compras, remanejamento automatizado de produtos entre drogarias da mesma rede;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>E-Pharma: integração direta entre indústrias farmacêuticas e sua rede de farmácias;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Módulo completo para gestão de produtos controlados e totalmente homologado pela ANVISA;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Sistema integrado com a Farmácia Popular desde a autorização até a finalização da venda.</span>
                            </p>
                        </div>
                        <img src={require(`../../assets/negocios/Farmácias.jpg`)} alt={"capa-automacao"} className="object-top object-cover w-full h-auto" />
                    </div>

                    <button className='bg-[#e39c00] hover:bg-[#e05e14] text-white font-bold py-2 px-4 mt-4 mb-20 rounded-md transition duration-200 shadow hover:shador-2xl'>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='text-white font-bold'>
                            Fale agora com nosso setor de Automação
                        </a>
                    </button>
                </div>

                <div>
                    <h1 className='w-full text-[#e39c00] text-3xl font-bold mt-16 z-10'>Sistema de automação para Postos de combustíveis</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-8 pb-32 text-left md:p-16 p-8'>
                        <img src={require(`../../assets/negocios/Postos-de-combustível.jpg`)} alt={"capa-automacao"} className="object-top object-cover w-full h-auto" />
                        <div className='flex flex-col space-y-2 lg:order-none order-last'>
                            <p className='mb-4 text-gray-500'>
                                Sistema em nuvem desenvolvido com a mais avançada tecnologia e ferramentas de gestão ideais para postos de combustível.
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Sistema totalmente em nuvem;</span>
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Backup automático das informações de sua empresa por meio do Cloud Computing;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Acesso móvel a todas informações do posto de combustível e/ou loja de conveniência por meio de smartphone ou tablet;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Sistema prático com interface gráfica agradável e de fácil operação.</span>
                            </p>
                        </div>
                    </div>


                    <button className='bg-[#e39c00] hover:bg-[#e05e14] text-white font-bold py-2 px-4 mt-4 mb-20 rounded-md transition duration-200 shadow hover:shador-2xl'>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='text-white font-bold'>
                            Fale agora com nosso setor de Automação
                        </a>
                    </button>
                </div>



                <div>
                    <h1 className='w-full text-[#e39c00] text-3xl font-bold mt-16 z-10'>Sistema de automação para Bares e restaurantes</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-8 pb-32 text-left md:p-16 p-8'>
                        <div className='flex flex-col space-y-2 lg:order-none order-last'>
                            <p className='mb-4 text-gray-500'>
                                Solução de automação que permite a centralização de processos através de relatórios e gráficos para análise dos resultados.
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Ideal para frente de loja;</span>
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Sistema responsivo;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Plataforma operacional para gestão, integrando financeiro, estoque e operacional;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Integração direta com o iFood.</span>
                            </p>
                        </div>
                        <img src={require(`../../assets/negocios/Bares-e-restaurantes.jpg`)} alt={"capa-automacao"} className="object-top object-cover w-full h-auto" />
                    </div>

                    <button className='bg-[#e39c00] hover:bg-[#e05e14] text-white font-bold py-2 px-4 mt-4 mb-20 rounded-md transition duration-200 shadow hover:shador-2xl'>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='text-white font-bold'>
                            Fale agora com nosso setor de Automação
                        </a>
                    </button>
                </div>

                <div>
                    <h1 className='w-full text-[#e39c00] text-3xl font-bold mt-16 z-10'>Sistema de automação para Autopeças</h1>
                    <div className='grid grid-cols-1 lg:grid-cols-2 items-center gap-8 pb-32 text-left md:p-16 p-8'>
                        <img src={require(`../../assets/negocios/Autopeça.jpg`)} alt={"capa-automacao"} className="object-top object-cover w-full h-auto" />
                        <div className='flex flex-col space-y-2 lg:order-none order-last'>
                            <p className='mb-4 text-gray-500'>
                                A Solução ideal para lojas de autopeças. Como nós chegamos lá? Desenvolvemos nosso software junto com lojas reais, incrementando e aperfeiçoando recursos para que sua empresa tenha o melhor resultado.
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Sistema em nuvem;</span>
                            </p>
                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Controle financeiro;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Controle de estoque integrado ao ponto de venda;</span>
                            </p>

                            <p className='flex flex-row items-center text-[#e39c00] font-semibold space-x-2'>
                                <CheckIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                                <span>Relatórios gerenciais.</span>
                            </p>
                        </div>
                    </div>

                    <button className='bg-[#e39c00] hover:bg-[#e05e14] text-white font-bold py-2 px-4 mt-4 mb-20 rounded-md transition duration-200 shadow hover:shador-2xl'>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='text-white font-bold'>
                            Fale agora com nosso setor de Automação
                        </a>
                    </button>
                </div>
            </div>

        </main>
    )
}
