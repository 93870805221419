import React from 'react'
import { ClockIcon, DeviceMobileIcon, MailIcon, } from '@heroicons/react/outline'
import { ChevronRightIcon, LocationMarkerIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

export default function InformacoesEmpresa() {
    return (
        <div className='max-w-6xl mx-auto grid lg:grid-cols-3 grid-cols-1 lg:space-x-8 space-y-8'>
            <div className='flex flex-col text-left'>
                <img
                    className="h-16 w-auto object-cover self-center"
                    src={require('../../../assets/logo_colorida_nome.png')}
                    alt=""
                />
                <div className='flex flex-col space-y-2'>
                    <div className='flex flex-row items-center space-x-4'>
                        <DeviceMobileIcon className='h-8 p-2 w-auto shrink-0 text-white bg-[#e05e14] rounded-full' />
                        <a href="tel:5588997932595" className='text-[#e39c00] font-semibold cursor-pointer '>(88) 99793-2595</a>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <MailIcon className='h-8 p-2 w-auto shrink-0 text-white bg-[#e05e14] rounded-full' />
                        <a href="mailto:contato@casaaguiarsistemas.com.br" target="_blank" className='text-[#e39c00] font-semibold cursor-pointer break-all'>contato@casaaguiarsistemas.com.br</a>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <ClockIcon className='h-8 p-2 w-auto shrink-0 text-white bg-[#e05e14] rounded-full' />
                        <a className='text-[#e39c00] font-semibold cursor-pointer '>Seg à Sex – 08:00 às 18:00</a>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <LocationMarkerIcon className='h-8 p-2 w-auto shrink-0 text-white bg-[#e05e14] rounded-full' />
                        <a href="https://www.google.com/maps/place/Casa+Aguiar+-+Sistemas+de+Automa%C3%A7%C3%A3o+Comercial/@-3.1195947,-40.8282603,15z/data=!4m2!3m1!1s0x0:0x82f83e9a6f40b195?sa=X&ved=2ahUKEwiP3KCsgdPtAhXmIbkGHY1zAosQ_BIwCnoECBcQBQ" target="_blank" className='text-[#e39c00] font-semibold cursor-pointer '>Rua Pessoa Anta, 532 - Sala 25, 2º piso, Centro - Granja, CE, 62430-000</a>
                    </div>
                </div>
            </div>
            <div className='flex flex-col'>
                <h6 className='text-gray-500 font-semibold mb-4 text-2xl'>Links rápidos</h6>
                <div className='flex flex-col space-y-2'>
                    <Link to="/solucoes-em-automacao" className='flex flex-row cursor-pointer items-center text-[#e39c00] font-semibold'>
                        <ChevronRightIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                        <span>Soluções em Automação</span>
                    </Link>
                    <Link to="/equipamentos" className='flex flex-row cursor-pointer items-center text-[#e39c00] font-semibold'>
                        <ChevronRightIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                        <span>Equipamentos</span>
                    </Link>

                    <Link to="/a-empresa" className='flex flex-row cursor-pointer items-center text-[#e39c00] font-semibold'>
                        <ChevronRightIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                        <span>Sobre Nós</span>
                    </Link>

                    <Link to="/fale-conosco" className='flex flex-row cursor-pointer items-center text-[#e39c00] font-semibold'>
                        <ChevronRightIcon className='h-6 w-auto shrink-0 text-[#c2410c]' />
                        <span>Fale Conosco</span>
                    </Link>
                </div>
            </div>
            <div className='flex flex-col items-center'>
                <h6 className='text-gray-500 font-semibold mb-4 text-2xl'>Siga nossas Redes Sociais</h6>
                <div className='flex flex-row space-x-4 justiy-center'>
                    <a href="https://www.instagram.com/casaaguiarsistemas/" target="_blank">
                        <svg height="32" fill="white" className='cursor-pointer shadow p-3 h-12 w-12 bg-blue-900 object-contain rounded-lg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
                    </a>
                    <a href="https://www.instagram.com/casaaguiarsistemas/" target="_blank">
                        <svg height="32" fill="white" className='cursor-pointer shadow p-3 h-12 w-12 bg-gray-900 object-contain rounded-lg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                    </a>
                </div>
            </div>
        </div>
    )
}
