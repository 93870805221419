import { ChatIcon, PhoneIcon, XIcon } from '@heroicons/react/solid'
import React from 'react'

export default function SugestaoAtendimento({ close }) {
    return (
        <div onClick={close} className='fixed h-full w-full flex flex-col items-center justify-center gap-12 text-white bg-[#c2410cbb] z-50 p-4 lg:p-64'>
            <button onClick={close} className='w-full flex flex-col items-end'>
                <XIcon className='text-white w-12 h-12 font-bold' />
                Fechar
            </button>
            <h1 className='text-4xl font-bold'>Não encontrou o que estava procurando?</h1>
            <p className='text-lg font-bold'>Nosso atendimento online vai ajudar você! Fale conosco e conheça as soluções ideais para melhorar os resultados da sua empresa.</p>
            <p>Envie sua mensagem pelo <b>WhatsApp</b> ou pelo <b>chat do site</b>.</p>

            <div className='grid sm:grid-cols-2 grid-cols-1 sm:gap-64 gap-4 justify-between'>
                <a
                    href="https://web.whatsapp.com/send?phone=5588997932595&text=Ol%C3%A1!%0AGostaria%20de%20saber%20mais%20sobre%20Sistemas%20de%20Automa%C3%A7%C3%A3o%20para%20o%20meu%20neg%C3%B3cio."
                    target="_blank"
                    className="p-4 bg-white text-[#e05e14] font-semibold flex flex-col items-center rounded-lg w-full z-20">
                    <PhoneIcon className='h-5 w-5 p-[2px] rounded-full border-2 border-[#e05e14]' />
                    <p>WHATSAPP</p>
                </a>
                <a
                    href="https://servidorseguro.mysuite1.com.br/empresas/csag/verifica.php"
                    target="_blank"
                    className="p-4 bg-white text-[#e05e14] font-semibold flex flex-col items-center rounded-lg w-full z-20">
                    <ChatIcon className='h-5 w-5' />
                    <p>CHAT</p>
                </a>
            </div>
        </div>
    )
}
