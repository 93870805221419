import React, { useEffect, useState } from 'react'
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import useWindowDimensions from '../../../hooks/getWindowDimensions';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function Carrossel() {
    const { height, width } = useWindowDimensions();

    const quantidadeImagens = 4;
    const [imagensMobile, setImagensMobile] = useState(null);
    const [imagensDeskTop, setImagensDeskTop] = useState(null);

    function preencheListasImagens(tipoLista) {
        let listaImagens = [];
        for (let i = 1; i <= quantidadeImagens; i++) {
            if (tipoLista == "mobile") listaImagens.push(require(`../../../assets/carrossel/Slide-${i}-Cels.jpg`))
            else listaImagens.push(require(`../../../assets/carrossel/Slide-${i}-Desktop.jpg`))
        }
        return listaImagens;
    }

    useEffect(() => {
        if (!imagensDeskTop) setImagensDeskTop(preencheListasImagens("desktop"));
        if (!imagensMobile) setImagensMobile(preencheListasImagens("mobile"));
    }, [])

    return (
        <>
            {width >= 768 &&
                <Carousel
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={true}
                    autoPlay={true}
                    interval={5000}
                    stopOnHover={false}
                    emulateTouch={true}
                >
                    {imagensDeskTop?.map((imagem, index) => {
                        return (
                            <img key={index + "Desktop"} className='object-contain h-auto w-full object-contain' src={imagem} alt={`Slide ${index + 1}`} />
                        )
                    })}
                </Carousel>}

            {width <= 768 &&
                <Carousel
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    showArrows={true}
                    autoPlay={true}
                    interval={5000}
                    stopOnHover={false}
                    emulateTouch={true}
                >
                    {imagensMobile?.map((imagem, index) => {
                        return (
                            <img key={index + "Mobile"} className='object-contain h-auto w-full object-contain' src={imagem} alt={`Slide ${index + 1}`} />
                        )
                    })}
                </Carousel>}
        </>
    )
}
