import React from 'react'
import { CashIcon, ChevronRightIcon, ClipboardCheckIcon, IdentificationIcon, LocationMarkerIcon } from '@heroicons/react/solid'

export default function CertificadoDigital() {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 text-left'>
            <img src={require('../../../assets/CERTIFICADO-DIGITAL-SITE.png')} alt='logo' className='xl:w-4/6 w-full p-8 self-center lg:justify-self-end justify-self-center' />
            <div className='xl:w-4/6 w-full flex flex-col space-y-4 p-8 self-center lg:justify-self-start justify-self-center border-l-2 lg:border-gray-400'>
                <h2 className='text-6xl text-[#e05e14] font-bold'>Certificado Digital</h2>
                <h6 className='text-4xl text-[#e39c00] font-bold'>O jeito mais seguro de fazer negócio no mundo eletrônico.</h6>
                <div className='flex flex-row items-center space-x-4'>
                    <ClipboardCheckIcon className='h-12 p-2 w-auto shrink-0 text-white bg-[#e39c00] rounded-full' /> <p className=' text-2xl text-gray-500 font-semibold'>Assinatura digital com garantia de Autenticidade e Segurança.</p>
                </div>
                <div className='flex flex-row items-center space-x-4'>
                    <IdentificationIcon className='h-12 p-2 w-auto shrink-0 text-white bg-[#e39c00] rounded-full' /><p className=' text-2xl text-gray-500 font-semibold'>Formalização de transações à distância.</p>
                </div>
                <div className='flex flex-row items-center space-x-4'>
                    <CashIcon className='h-12 p-2 w-auto shrink-0 text-white bg-[#e39c00] rounded-full' /><p className=' text-2xl text-gray-500 font-semibold'>Agilidade nos processos e redução de custos de operações.</p>
                </div>

                <button className='w-fit self-center bg-yellow-500 hover:bg-[#e05e14] text-white font-bold py-2 px-4 my-4 rounded-md transition duration-200 shadow hover:shador-2xl'>
                    <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className='w-fit text-white font-bold'>
                        Garanta o seu Certificado Digital
                    </a>
                </button>
            </div>

        </div>
    )
}
