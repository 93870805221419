import React from 'react'

export default function Clientes() {
    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../../../assets/clientes', false, /\.(png|jpe?g|svg)$/));

    return (
        <div className='flex flex-col items-center max-w-6xl mx-auto'>
            <div className='flex flex-col items-center py-4 gap-4'>
                <h1 className='text-3xl text-white font-bold'>Mais de 300 empresas escolheram trabalhar com nossas soluções para alcançar melhores resultados.</h1>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 w-full gap-4 py-4'>
                {images.map((image, index) => (
                    <img key={index} src={image} alt='logo' className='w-full h-auto bg-gray-200 object-contain rounded-lg shadow hover:shadow-2xl transition duration-200' />
                ))}
            </div>
        </div>
    )
}
