import { useRoutes } from "react-router-dom";
import AEmpresa from "../pages/AEmpresa";
import Home from "../pages/Home";
import LayoutDashboard from "../components/LayoutDashboard";
import SolucoesAutomacao from "../pages/SolucoesAutomacao";
import Equipamentos from "../pages/Equipamentos";
import FaleConosco from "../pages/FaleConosco";

function Router() {
    const routes = [
        {
            path: "/",
            element: <LayoutDashboard />,
            children: [
                {
                    path: "/",
                    element: <Home />,
                },
                {
                    path: "a-empresa",
                    element: <AEmpresa />,
                },
                {
                    path: "solucoes-em-automacao",
                    element: <SolucoesAutomacao />,
                },
                {
                    path: "equipamentos",
                    element: <Equipamentos />,
                },
                {
                    path: "fale-conosco",
                    element: <FaleConosco />,
                },
            ],
        },
    ];

    return useRoutes(routes);
}

export default Router;
