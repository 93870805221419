import React from 'react'
import Carrossel from './SessoesHome/Carrossel';
import CertificadoDigital from './SessoesHome/CertificadoDigital';
import Clientes from './SessoesHome/Clientes';
import SolucoesAutomacao from './SessoesHome/SolucoesAutomacao';
import TipoNegocio from './SessoesHome/TipoNegocio';

export default function Home() {
    return (
        <main className='bg-gray-50 text-center'>
            <section className='h-fit w-full bg-[#737277] shadow-inner'>
                <Carrossel />
            </section>
            <section className='h-fit w-full bg-[#dfdfdf] md:p-16 p-8'>
                <TipoNegocio />
            </section>
            <section className='h-fit w-full bg-[#737277] shadow-inner md:p-16 p-8'>
                <SolucoesAutomacao />
            </section>
            <section className='h-fit w-full bg-[#dfdfdf] md:p-16 p-8' id="certificado-digital">
                <CertificadoDigital />
            </section>
            <section className='h-fit w-full bg-[#e05e14] md:p-8 p-16'>
                <Clientes />
            </section>
        </main>
    )
}
