import React from 'react'
import CabecalhoImagem from '../../components/CabecalhoImagem'

export default function AEmpresa() {
    return (
        <main className='bg-gray-50 h-fit'>
            <CabecalhoImagem imagem="cabecalho/equipe-casa-aguiar-capa" titulo="A Empresa" />
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-8 py-32 text-left md:p-16 p-8 max-w-6xl mx-auto'>
                <img src={require(`../../assets/equipe-casa-aguiar.jpg`)} alt={"equipe-casa-aguiar-capa"} className="lg:order-2 object-top object-cover w-full h-auto" />
                <div className='grid grid-cols-1 gap-8 text-gray-500 text-lg'>
                    <p>
                        A <b>Casa Aguiar Sistemas</b> teve seu início no mês de Fevereiro de 2019, a partir do desejo do fundador Nonato Aguiar em oferecer um atendimento de excelência aos clientes, levando em conta suas necessidades e oferecendo a resolução na área de automação comercial.
                    </p>
                    <p>
                        O nome <b>Casa Aguiar</b> surgiu da vontade de expressar a confiabilidade que seu nome carrega, pois é o oferecido pela empresa recém-instalada no mercado. E com imensa alegria destacamos a efetivação de nossos sonhos nesse tempo em que estamos no mercado. Isso se expressa com a satisfação que as centenas de clientes relatam, a preparação de nossos colaboradores e também o cuidado em realizarmos com responsabilidade a nossa proposta: oferecer tecnologia e facilidade aos clientes.
                    </p>
                    <b>
                        Somos a Casa Aguiar Sistemas e continuamos trabalhando dia após dia para ser a empresa que você quer ao seu lado!
                    </b>
                </div>
            </div>
        </main>
    )
}
