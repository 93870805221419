import React from 'react'

export default function TipoNegocio() {
    return (
        <div className='max-w-6xl mx-auto'>

            <div className='flex flex-col items-center py-4 gap-4'>
                <h1 className='text-2xl text-gray-600'>Com Sistemas de Informação você pode fazer negócios de um jeito diferente.</h1>
                <h6 className='text-4xl text-[#e05e14] font-bold'>Qual seu tipo de Negócio?</h6>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-5 gap-6 py-4 row-span-2'>
                <div className='h-full flex flex-col space-y-4 shadow cursor-default hover:shadow-2xl transition duration-200 bg-[#e39c00] hover:bg-[#e05e14] rounded-2xl overflow-hidden p-4 items-center'>
                    <img src={require('../../../assets/negocios/Varejo.jpg')} alt='logo' className='w-full h-auto lg:max-h-32 max-h-72 bg-gray-200 rounded-t-2xl object-contain' />
                    <h3 className='text-2xl text-white font-bold'>Varejo</h3>
                    <p className='font-semibold text-lg text-gray-200 '>Acompanhamento de vendas, controle de estoque, precificação, metas e resultados.</p>
                </div>
                <div className='h-full flex flex-col space-y-4 shadow cursor-default hover:shadow-2xl transition duration-200 bg-[#e05e14] hover:hover:bg-[#e39c00] rounded-2xl overflow-hidden p-4 items-center'>
                    <img src={require('../../../assets/negocios/Farmácias.jpg')} alt='logo' className='w-full h-auto lg:max-h-32 max-h-72 bg-gray-200 rounded-t-2xl object-contain' />
                    <h3 className='text-2xl text-white font-bold'>Farmácias</h3>
                    <p className='font-semibold text-lg text-gray-200 '>Gestão completa para sua rede de farmácias. Automatize processos e controle seu caixa, estoque e indicadores.</p>
                </div>
                <div className='h-full flex flex-col space-y-4 shadow cursor-default hover:shadow-2xl transition duration-200 bg-[#e39c00] hover:bg-[#e05e14] rounded-2xl overflow-hidden p-4 items-center'>
                    <img src={require('../../../assets/negocios/Postos-de-combustível.jpg')} alt='logo' className='w-full h-auto lg:max-h-32 max-h-72 bg-gray-200 rounded-t-2xl object-contain' />
                    <h3 className='text-2xl text-white font-bold'>Postos de Combustível​</h3>
                    <p className='font-semibold text-lg text-gray-200 '>Gestão perfeita! Integração da bomba de combustível à tela do computador do setor administrativo.</p>
                </div>
                <div className='h-full flex flex-col space-y-4 shadow cursor-default hover:shadow-2xl transition duration-200 bg-[#e05e14] hover:hover:bg-[#e39c00] rounded-2xl overflow-hidden p-4 items-center'>
                    <img src={require('../../../assets/negocios/Bares-e-restaurantes.jpg')} alt='logo' className='w-full h-auto lg:max-h-32 max-h-72 bg-gray-200 rounded-t-2xl object-contain' />
                    <h3 className='text-2xl text-white font-bold'>Bares e Restaurantes</h3>
                    <p className='font-semibold text-lg text-gray-200 '>Controle total de todos os pedidos, gestão de delivery, cozinha e estoque.</p>
                </div>
                <div className='h-full flex flex-col space-y-4 shadow cursor-default hover:shadow-2xl transition duration-200 bg-[#e39c00] hover:bg-[#e05e14] rounded-2xl overflow-hidden p-4 items-center'>
                    <img src={require('../../../assets/negocios/Autopeça.jpg')} alt='logo' className='w-full h-auto lg:max-h-32 max-h-72 bg-gray-200 rounded-t-2xl object-contain' />
                    <h3 className='text-2xl text-white font-bold'>Autopeças</h3>
                    <p className='font-semibold text-lg text-gray-200 '>Controle seu estoque sem erros e integre-o ao seu ponto de venda.</p>
                </div>
            </div>
        </div>
    )
}
