import React from 'react'
import CabecalhoImagem from '../../components/CabecalhoImagem'

export default function Equipamentos() {

    function importAll(r) {
        return r.keys().map(r);
    }

    const imagensModulos = importAll(require.context('../../assets/equipamentos/modulos', false, /\.(png|jpe?g|svg)$/));
    const imagensConcentradores = importAll(require.context('../../assets/equipamentos/concentradores', false, /\.(png|jpe?g|svg)$/));
    const imagensImpressoras = importAll(require.context('../../assets/equipamentos/impressoras', false, /\.(png|jpe?g|svg)$/));
    const imagensLeitores = importAll(require.context('../../assets/equipamentos/leitores', false, /\.(png|jpe?g|svg)$/));

    const modulos = [
        {
            nome: "MFE Elgin Linker",
            descricao: "Desenvolvido para atender à legislação fiscal na emissão de cupons fiscais eletrônicos do estado do Ceará. Possui duas portas de rede ethernet, dispensando a necessidade de instalação de um ponto adicional.",
            imagem: imagensModulos[0]
        },

        {
            nome: "MFE Gertec",
            descricao: "Com o Módulo Fiscal Eletrônico os CF-e são transmitidos via internet para a SEFAZ/CE. Além de mais praticidade e segurança, substitui a impressora fiscal – ECF.",
            imagem: imagensModulos[1]
        },

        {
            nome: "Smart MFE Elgin",
            descricao: "Atendendo à legislação fiscal para o comércio varejista, o SMART MFE Elgin utiliza os mesmos drivers que o Linker e o Linker II. O SAT Elgin ainda traz uma grande novidade para o mercado que é a tela LCD contendo todas as informações e status do MFE.",
            imagem: imagensModulos[2]
        },
    ]

    const concentradores = [

        {
            nome: "Concentrador EZForecourt Plus 2GS",
            descricao: "Essa é a principal solução para postos grandes e que não querem se preocupar com infraestrutura ou falha de comunicação. O EZForecourt Plus Wireless não possui limite de bombas nem de bicos, se comunicando com as bombas através de terminais remotos instalados dentro delas.",
            imagem: imagensConcentradores[0]
        },

        {
            nome: "Concentrador EZForecourt Plus",
            descricao: "Trata-se do equipamento mais vendido para fazer automação das bombas. Consegue se comunicar com até 16 bombas, conectadas em seus 4 slots através de cabos de dados. Seu processador de ponta é multifuncional, sendo integrado com as principais bombas, softwares de gestão e medidores de tanque do mercado.",
            imagem: imagensConcentradores[1]
        },
    ]

    const impresoras = [
        {
            nome: "Impressora i9 USB Elgin",
            descricao: "A impressora i9, está disponível em três versões diferentes com relação à conexão: USB ou USB+Serial ou USB+Ethernet. Concebida para proteger o investimento do cliente, na versão USB é possível adicionar uma outra interface: Serial ou Ethernet ou Paralela.",
            imagem: imagensImpressoras[0]
        },

        {
            nome: "Impressora i7 USB Elgin",
            descricao: "Ideal para emissão de cupons do MFE / NFC-e, e também outros cupons e recibos não fiscais, a impressora térmica não fiscal i7 é a melhor escolha entre preço e desempenho.",
            imagem: imagensImpressoras[1]
        },
    ]

    const leitores = [
        {
            nome: "Leitor de código de barras sem fio Knup",
            descricao: "Produzido com técnicas avançadas possui um amplo ângulo de leitura via Beep e LED indicador, sua linha mais fina proporciona maior precisão e rapidez na leitura, programado para melhor leitura de códigos incompletos ou bagunçados.",
            imagem: imagensLeitores[0]
        }
        ,

        {
            nome: "Leitor de código de barras flash infravermelho Elgin",
            descricao: "O leitor Flash é imbatível em sua categoria, é a melhor escolha entre preço x desempenho. Projetado para atender todos os segmentos de varejo de pequeno a médio porte, ele é ágil e ergonômico.",
            imagem: imagensLeitores[1]
        }
        ,

        {
            nome: "Leitor de código de barras laser Goldentec",
            descricao: "O Goldentec GT62 é um Leitor de Código de Barras que utiliza a tecnologia de leitura a Laser. Este Leitor de Código de Barras utiliza a interface USB, que garante maior praticidade, por ser compatível com todos os sistemas operacionais.",
            imagem: imagensLeitores[2]
        }
        ,

        {
            nome: "Leitor fixo 2D USB Elgin",
            descricao: "O seu poderoso conjunto óptico e software de reconhecimento de imagem, permitem que o EL5220 leia na primeira passagem, códigos do tipo 1D ou 2D, danificados ou com baixa qualidade, sem a preocupação de alinhar o código de barras.",
            imagem: imagensLeitores[3]
        }
        ,

        {
            nome: "Leitora de cartão Smartcard Gemalto",
            descricao: "O leitor Gemalto faz a leitura e gravação de cartões smart cards (cartões inteligentes) em um equipamento compacto e de baixo custo. O IDBridge permite a realização de diversas operações com rapidez e segurança.",
            imagem: imagensLeitores[4]
        }
    ]

    const Produto = (props) => {
        const { nome, imagem, descricao } = props;
        return (
            <div className='p-4 flex flex-col gap-4 hover:shadow-inner border-2 border-gray-200 hover:border-gray-400 transition duration-200 cursor-default'>
                <img src={imagem} alt={nome} className="object-contain w-full h-32 justify-self-center" />
                <h1 className='text-[#e39c00] text-2xl font-semibold text-center'>{nome}</h1>
                <p>{descricao}</p>
            </div>
        )
    }

    return (
        <main className='bg-gray-50 h-fit text-center'>
            <CabecalhoImagem imagem="cabecalho/capa-equipamento" titulo="Conheça nossos Equipamentos" />
            <div className='grid grid-cols-1 gap-8 py-32 text-left md:p-16 p-8 max-w-6xl mx-auto'>
                <div className='lg:flex flex-row'>
                    <div className='relative lg:w-2/6 lg:h-full p-8 flex text-center flex-col items-center justify-center gap-4'>
                        <h1 className='text-3xl text-white font-bold z-20'>Módulo Fiscal Eletrônico</h1>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" taget="_blank" className="p-4 bg-white text-[#e05e14] font-semibold rounded-lg w-5/6 z-20">
                            Compre agora pelo WhatsApp
                        </a>
                        <div className='absolute z-10 w-full h-full bg-gradient-to-b from-transparent to-[#e39c00] hover:to-[#e05e14] transition duration-200'></div>
                        <img src={require('../../assets/equipamentos/modulo.jpg')} alt={"equipamento-1"} className="object-cover w-full h-full absolute" />
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 flex-1 p-4'>
                        {modulos.map((modulo, index) => (
                            <Produto key={index} {...modulo} />
                        ))}
                    </div>
                </div>
                <div className='lg:flex flex-row'>
                    <div className='relative lg:w-2/6 lg:h-full p-8 flex text-center flex-col items-center justify-center gap-4'>
                        <h1 className='text-3xl text-white font-bold z-20'>Concentradores</h1>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" taget="_blank" className="p-4 bg-white text-[#e05e14] font-semibold rounded-lg w-5/6 z-20">
                            Compre agora pelo WhatsApp
                        </a>
                        <div className='absolute z-10 w-full h-full bg-gradient-to-b from-transparent to-[#e39c00] hover:to-[#e05e14] transition duration-200'></div>
                        <img src={require('../../assets/equipamentos/concentrador.jpg')} alt={"equipamento-1"} className="object-cover w-full h-full absolute" />
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 flex-1 p-4'>
                        {concentradores.map((modulo, index) => (
                            <Produto key={index} {...modulo} />
                        ))}
                    </div>
                </div>
                <div className='lg:flex flex-row'>
                    <div className='relative lg:w-2/6 lg:h-full p-8 flex text-center flex-col items-center justify-center gap-4'>
                        <h1 className='text-3xl text-white font-bold z-20'>Impressoras</h1>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className="p-4 bg-white text-[#e05e14] font-semibold rounded-lg w-5/6 z-20">
                            Compre agora pelo WhatsApp
                        </a>
                        <div className='absolute z-10 w-full h-full bg-gradient-to-b from-transparent to-[#e39c00] hover:to-[#e05e14] transition duration-200'></div>
                        <img src={require('../../assets/equipamentos/impressoras.jpg')} alt={"equipamento-1"} className="object-cover w-full h-full absolute" />
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 flex-1 p-4'>
                        {impresoras.map((modulo, index) => (
                            <Produto key={index} {...modulo} />
                        ))}
                    </div>
                </div>
                <div className='lg:flex flex-row'>
                    <div className='relative lg:w-2/6 lg:h-full p-8 flex text-center flex-col items-center justify-center gap-4'>
                        <h1 className='text-3xl text-white font-bold z-20'>Leitores</h1>
                        <a href="https://api.whatsapp.com/send?phone=5588997932595" target="_blank" className="p-4 bg-white text-[#e05e14] font-semibold rounded-lg w-5/6 z-20">
                            Compre agora pelo WhatsApp
                        </a>
                        <div className='absolute z-10 w-full h-full bg-gradient-to-b from-transparent to-[#e39c00] hover:to-[#e05e14] transition duration-200'></div>
                        <img src={require('../../assets/equipamentos/leitor.jpg')} alt={"equipamento-1"} className="object-cover w-full h-full absolute" />
                    </div>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 flex-1 p-4'>
                        {leitores.map((modulo, index) => (
                            <Produto key={index} {...modulo} />
                        ))}
                    </div>
                </div>
            </div>
        </main>
    )
}
