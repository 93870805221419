import instance from '../configAxios';

export const enviarMensagem = async novoCadastroLead => {
    const axios = await instance();
  
    try {
      const response = await axios.post(`/lead`, novoCadastroLead)
      return { sucesso: true, data: response.data }
    } catch (error) {
      if (!error.response) {
        return {
          sucesso: false,
          mensagem: "Não foi possível conectar ao servidor."
        }
      } else {
        return {
          sucesso: false,
          mensagem: error.response.data
        }
      }
    }
  }