import React from 'react'

export default function CabecalhoImagem(props) {
    const { imagem, titulo } = props;

    return (
        <div className='relative h-80 w-full jsutify-center bg-gray-200 text-center'>
            <img src={require(`../../assets/${imagem}.jpg`)} alt={titulo} className="absolute opacity-20 object-top object-cover w-full h-full" />
            <h1 className='absolute top-1/2 -mt-4 w-full text-[#e39c00] text-5xl font-bold z-10'>{titulo}</h1>
        </div>
    )
}
