import React, { useState, useEffect } from 'react'
import { PhoneIcon } from '@heroicons/react/solid'
import CabecalhoImagem from '../../components/CabecalhoImagem'
import { enviarMensagem } from '../../services/mensagem'
import InputMask from 'react-input-mask';
import useDebounce from '../../hooks/useDebounce';

export default function FaleConosco() {
    const enviarMensagemDebounce = useDebounce(hanleEnviarMensagem, 500)
    const [mensagemEnviada, setMensagemEnviada] = useState(false)
    const [form, setForm] = useState({
        nome: null,
        email: null,
        telefone: null,
        empresa: null,
        mensagem: null,
    })
    const [erro, setErro] = useState(null);

    useEffect(() => {
        return () => {
            setMensagemEnviada(false)
            setForm({
                nome: null,
                email: null,
                telefone: null,
                empresa: null,
                mensagem: null,
            })
        }
    }, [])

    const unmask = (value) => {
        if (!value) return value;
        return value.replace(/[^\wÀ-ú]/g, '')
    }

    async function hanleEnviarMensagem() {
        form.telefone = unmask(form.telefone)
        const response = await enviarMensagem(form)

        if (response.sucesso) {
            setForm({
                nome: null,
                email: null,
                telefone: null,
                empresa: null,
                mensagem: null,
            })

            setMensagemEnviada(true)
        } else {
            setErro("Não foi possível enviar mensagem.")
        }
    }

    return (
        <main className='bg-gray-50 h-fit text-center'>
            <CabecalhoImagem imagem="cabecalho/Fale-conosco" titulo="Fale Conosco" />

            <div className='grid grid-cols-1 gap-8 py-32 text-left md:p-16 p-8 max-w-6xl mx-auto'>
                <div className='lg:flex flex-row gap-8'>
                    <div className='relative lg:w-2/6 h-fit text-center grid grid-cols-1 gap-4 py-12'>
                        <div className='text-2xl font-bold text-white bg-[#e39c00] px-10 py-4 w-full h-fit rounded-2xl cursor-default'>
                            Ligue para nossos setores de atendimento
                        </div>
                        <a href="tel:88997932595" target="_blank" className='flex flex-col items-center gap-4 text-white bg-[#e39c00] px-10 py-4 w-full h-fit rounded-2xl'>

                            <PhoneIcon className='h-12 p-2 w-auto shrink-0 bg-white text-[#e39c00] rounded-full' />
                            <h1 className='text-2xl font-bold'>(88) 99793-2595</h1>
                            <p>Automação Comercial</p>
                        </a>
                        <a href="tel:88999572596" target="_blank" className='flex flex-col items-center gap-4 text-white bg-[#e39c00] px-10 py-4 w-full h-fit rounded-2xl'>
                            <PhoneIcon className='h-12 p-2 w-auto shrink-0 bg-white text-[#e39c00] rounded-full' />
                            <h1 className='text-2xl font-bold'>(88) 99957 2596</h1>
                            <p>Certificação Digital</p>
                        </a>
                    </div>

                    {mensagemEnviada ?
                        <div className='flex-1 flex flex-col justify-center text-center gap-4 text-3xl text-[#e39c00] font-bold'>
                            <h1>Sua mensagem foi enviada!</h1>
                            <p className='text-lg text-center'>
                                Em breve entraremos em contato com você.
                            </p>
                        </div>
                        :
                        <form className='flex flex-col gap-8'>
                            <h1 className='text-3xl text-[#e39c00] lg:text-end text-center font-bold'>Mande sua mensagem para nossa equipe</h1>

                            <div className='flex flex-col gap-1'>
                                <label className='text-[#e05e14]'>Seu Nome:*</label>
                                <input
                                    onInput={(e) => setForm({ ...form, nome: e.target.value })}
                                    type='text'
                                    className='bg-white px-4 py-2 border-2 border-[#e05e14] rounded-lg'
                                    placeholder='Informe-nos seu nome por favor.' />
                            </div>

                            <div className='flex flex-col gap-1'>
                                <label className='text-[#e05e14]'>Seu E-mail:*</label>
                                <input
                                    onInput={(e) => setForm({ ...form, email: e.target.value })}
                                    type='text'
                                    className='bg-white px-4 py-2 border-2 border-[#e05e14] rounded-lg'
                                    placeholder='Informe-nos seu melhor e-mail.' />
                            </div>

                            <div className='flex flex-col gap-1'>
                                <label className='text-[#e05e14]'>Seu Telefone (Whatsapp):*</label>
                                <InputMask
                                    onChange={(e) => setForm({ ...form, telefone: e.target.value })}
                                    mask="(99) 99999-9999"
                                    maskChar=""
                                    placeholder='Informe-nos seu número de contato.'
                                    className='bg-white px-4 py-2 border-2 border-[#e05e14] rounded-lg'
                                />
                            </div>

                            <div className='flex flex-col gap-1'>
                                <label className='text-[#e05e14]'>Nome da sua Empresa:</label>
                                <input
                                    onInput={(e) => setForm({ ...form, empresa: e.target.value })}
                                    type='text'
                                    className='bg-white px-4 py-2 border-2 border-[#e05e14] rounded-lg'
                                    placeholder='Diga-nos onde você trabalha, por favor.' />
                            </div>

                            <div className='flex flex-col gap-1'>
                                <label className='text-[#e05e14]'>Mensagem:*</label>
                                <textarea
                                    onInput={(e) => setForm({ ...form, mensagem: e.target.value })}
                                    cols="40"
                                    rows="5"
                                    className='bg-white px-4 py-2 border-2 border-[#e05e14] rounded-lg'
                                    aria-required="true"
                                    aria-invalid="false"
                                    placeholder="Fale um pouco mais..."></textarea>
                            </div>

                            <div className='flex flex-row justify-between flex-wrap items-center'>
                                {erro && <span className='bg-red-200 text-red-600 rounded-full px-4 py-2 font-semibold'>
                                    {erro}
                                </span>}

                                <div className='w-fit self-end bg-yellow-500 hover:bg-[#e05e14] text-white font-bold py-2 px-4 my-4 rounded-md transition duration-200 shadow hover:shador-2xl'>
                                    <button onClick={(e) => {
                                        e.preventDefault();
                                        enviarMensagemDebounce()
                                    }} className='w-fit text-white font-bold'>
                                        Enviar Mensagem
                                    </button>
                                </div>
                            </div>
                        </form>}
                </div>
            </div>
        </main >
    )
}
